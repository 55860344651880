<template>
  <div class="service-list">
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column label="服务内容名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.serviceName || "-" }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="图片" align="center" >
        <template slot-scope="scope">
          <el-image style="width: 28px; height: 28px" :src="scope.row.picUrl" :preview-src-list="[scope.row.picUrl]">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </template>
      </el-table-column> -->
      <el-table-column label="位置数量" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.roomCount || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="服务类型模版"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ serviceTemplate[scope.row.serviceTemplateType] }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="是否设置提前服务"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.advanceFlg ? "是" : "否" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="提前服务内容"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span
            >{{ scope.row.mahjongShift ? "麻将调档" : "" }}
            {{ scope.row.mahjongShift && scope.row.turnAir ? "；" : "" }}
            {{ scope.row.turnAir ? "提前开启空调（自动）" : "" }}</span
          >
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="medium"
            @click.stop="onEdit(scope.row)"
            v-has="'resource_service_edit'"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.size"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </div>
    <!-- 编辑弹框 -->
    <el-dialog
      :visible.sync="editShow"
      :title="editTitle"
      :close-on-click-modal="false"
      width="40%"
      custom-class="add-dialog"
    >
      <el-form :model="params" :rules="rules" ref="form-ref">
        <el-form-item
          label="服务内容名称"
          label-width="120px"
          prop="serviceName"
        >
          <el-input
            v-model.trim="params.serviceName"
            autocomplete="off"
            maxlength="8"
            placeholder="请填写服务内容名称"
          ></el-input>
        </el-form-item>
        <!--服务类型模版-->
        <el-form-item style="display: flex; align-items: center">
          <div style="display: flex; align-items: center" slot="label">
            <el-popover placement="top" width="800" trigger="click">
              <span>不同服务类型模版区别在于小程序端部分页面显示不同</span>
              <div class="templateList">
                <div class="templateItem">
                  <div class="txt">例：包厢类模版</div>
                  <img
                    class="img"
                    src="@/assets/images/service/type1.jpg"
                    alt=""
                  />
                </div>
                <div class="templateItem">
                  <div class="txt">台球类模版</div>
                  <img
                    class="img"
                    src="@/assets/images/service/type2.jpg"
                    alt=""
                  />
                </div>
                <div class="templateItem">
                  <div class="txt">
                    餐桌类模版（餐桌类服务内容服务及位置列表不在小程序端显示）
                  </div>
                  <img
                    class="img"
                    src="@/assets/images/service/type3.jpg"
                    alt=""
                  />
                </div>
              </div>
              <i
                class="el-icon-question"
                slot="reference"
                style="margin-right: 0.1rem"
              ></i>
            </el-popover>
            <span>服务类型</span>
          </div>
          <el-radio-group
            v-model="params.serviceTemplateType"
            style="display: flex; align-items: center"
          >
            <el-radio :label="1">包厢类</el-radio>
            <el-radio :label="2">台球类</el-radio>
            <el-radio :label="3">餐桌类</el-radio>
            <el-radio :label="4">仓储类</el-radio>
            <el-radio :label="5">工位类</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="服务类型"
          label-width="120px"
          prop="serviceName"
        >
        <div>{{ ['包厢类','台球类','餐桌类','仓储类','工位类'][params.serviceTemplateType-1] }}</div>
        
        </el-form-item>
        <el-form-item
          label="服务类型模版"
          label-width="120px"
          prop="serviceName"
        >
        <div>{{ params.serviceName }}</div>
        
        </el-form-item>
        <el-form-item
          label="服务内容图标"
          label-width="120px"
          prop="servicePicId"
        >
          <div class="upload-btn" @click="onUpload()">
            <img v-if="params.picUrl" :src="params.picUrl" class="upload-img" />
            <i v-else class="el-icon-plus uploader-icon"></i>
          </div>
          <p style="color: red">建议上传60X60像素，500kb以内的图片</p>
        </el-form-item>
        <el-form-item
          label="开启提前服务"
          label-width="120px"
          prop="advanceFlg"
        >
          <el-switch v-model="params.advanceFlg"></el-switch>
        </el-form-item>
        <template v-if="params.advanceFlg">
          <el-form-item label="选择手动服务内容" label-width="140px">
            <el-checkbox v-model="params.mahjongShift">麻将调档</el-checkbox>
          </el-form-item>
          <el-form-item
            v-if="params.turnAirFlg"
            label="选择自动服务内容"
            label-width="140px"
          >
            <el-checkbox v-model="params.turnAir">提前开启空调（自动）</el-checkbox>
          </el-form-item>
        </template>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editShow = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
      </div>
    </el-dialog>
    <UpLoad @fileIds="getFileIds" ref="up" :fileType="1"></UpLoad>
  </div>
</template>

<script>
import { serviceTemplate } from "@/db/objs";
import { getServiceList, getServiceEdit } from "@/api/resource/service";
import { ObjToOpt } from "@/utils/utils";
import UpLoad from "@/components/upload";
import { getFileList } from "@/api/common";
import { rules } from "@/db/rules";

export default {
  components: { UpLoad },
  data() {
    return {
      serviceTemplate,
      rules,
      ObjToOpt,
      // 列表
      currentPage: 1, // 当前页
      total: 0, // 总条数
      tableData: [],
      tableParams: {
        page: 1,
        size: 10,
        keyWord: "",
        shopId: "",
      },
      // 表单
      editShow: false, //弹框开关
      editTitle: "", //弹框姓名
      params: {
        serviceId: "", //ID
        serviceName: "", //名称
        picUrl: "", //名称
        servicePicId: "", //名称
        advanceFlg: false,
        mahjongShift: false,
        turnAir: false,
        turnAirFlg: false,
        serviceTemplateType: 1, // 服务模版类型
      },
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getServiceList();
  },
  methods: {
    // 【请求】列表
    getServiceList() {
      let data = this.tableParams;
      getServiceList(data).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },

    // 【请求】新增编辑
    getServiceEdit() {
      let data = this.params;
      getServiceEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getServiceList();
        }
      });
    },

    

    // 【监听】文件上传
    onUpload() {
      this.$refs.up.onUpload();
    },
    // 【请求】获取文件信息
    getFileList(fileIds) {
      let data = {
        fileIds,
      };
      getFileList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.params.picUrl = res.data[0].url;
          this.params.servicePicId = res.data[0].attId;
          this.$refs['form-ref'].validateField('servicePicId');
          this.$forceUpdate();
        }
      });
    },
    // 【监听】获取上传文件ID集合
    getFileIds(fileIds) {
      this.getFileList(fileIds);
    },

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getServiceList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getServiceList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },

    // 【监听】编辑
    onEdit(row) {
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
      console.log("row: ", row);
      this.editShow = true;
      this.editTitle = "编辑服务";
      
      if(row.picUrl==null){
        row.picUrl=''
      }
      if(row.servicePicId==null){
        row.servicePicId=''
      }
      this.params = JSON.parse(JSON.stringify(row));
     
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getServiceEdit();
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.service-list {
  padding: 0.15rem;
  box-sizing: border-box;
}

header {
  height: 0.6rem;
  padding: 0.1rem 0;
  display: flex;
  justify-content: space-between;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}

.header-L {
  display: flex;
}

.upload-btn {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  width: 1.6rem;
  height: 1.6rem;
}

.upload-btn:hover {
  border-color: #409eff;
}

.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 1.6rem;
  height: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
}

.upload-img {
  width: 1.6rem;
  height: 1.6rem;
  display: block;
}
.templateList {
  display: flex;
  justify-content: space-around;

  margin-top: 20px;
  .templateItem {
    width: 250px;
    .txt {
      height: 30px;
    }

    .img {
      width: 250px;
      height: 320px;
      margin-top: 20px;
    }
  }
}
</style>