import request from '@/utils/request'
// 列表
export function getServiceList(data) {
  return request('post', '/apm/service/contentList', data)
}

// 新增修改
export function getServiceEdit(data) {
  return request('post', '/apm/service/contentUpdate', data)
}

// 删除
export function getServiceDel(data) {
  return request('post', '/apm/shop/shopDel', data)
}